import {RECURRING_FILTER, EVENT_FILTER_TYPE} from '@wix/wix-events-commons-statics'
import {WidgetSettingsState} from '../reducers'
import {getEventFilterType} from './component-config'
import {hasRecurringEvents} from './events'

interface ActiveRecurringFilterParams {
  state: WidgetSettingsState
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const activeRecurringFilter = ({
  state,
  multiEventWidget,
  calendar,
  recurringFilter,
}: ActiveRecurringFilterParams) => {
  if (!hasRecurringEvents(state)) {
    return RECURRING_FILTER.ALL
  }

  if (calendar || getEventFilterType(state) === EVENT_FILTER_TYPE.MANUAL || !multiEventWidget) {
    return RECURRING_FILTER.ALL
  }

  return recurringFilter ?? RECURRING_FILTER.COLLAPSED
}
