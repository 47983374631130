import {
  ALIGNMENT,
  BUTTONS_STYLE,
  DEFAULT_WEEK_START,
  HORIZONTAL_IMAGE_POSITION,
  IMAGE_POSITION,
  IMAGE_RATIO,
  IMAGE_SCALING,
  LIST_ALIGNMENT,
  LIST_IMAGE_LAYOUT,
  LIST_LAYOUT,
  LIST_LOCATION_AND_DATE_FORMAT,
  LOCATION_AND_DATE_FORMAT,
  VERTICAL_IMAGE_POSITION,
  WIDGET_LAYOUT,
  WIDGET_TYPE,
} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import type {WidgetComponentTextSettings} from './settingsParams'
import type {WidgetComponentSettings} from './stylesParams'
import {WidgetFillSettingsDefaultsProps} from './Widget/actions/component'

// for params that may come from server.
export const defaultStyleParams = (rtl: boolean): Partial<WidgetComponentSettings> => ({
  widgetType: WIDGET_TYPE.LIST,
  listLayout: LIST_LAYOUT.NEW_GRID,
  widgetLayout: WIDGET_LAYOUT.FULL,
  alignment: ALIGNMENT.CENTER,
  contentAlignment: ALIGNMENT.CENTER,
  listAlignment: LIST_ALIGNMENT.CENTER,
  imagePosition: rtl ? HORIZONTAL_IMAGE_POSITION.LEFT : HORIZONTAL_IMAGE_POSITION.RIGHT,
  verticalImagePosition: VERTICAL_IMAGE_POSITION.TOP,
  imageGridPosition: IMAGE_POSITION.CENTER_CENTER,
  imageScaling: IMAGE_SCALING.CROP,
  imageRatio: IMAGE_RATIO['1:1'],
  listImageLayout: rtl ? LIST_IMAGE_LAYOUT.RIGHT : LIST_IMAGE_LAYOUT.LEFT,
  locationAndDateFormat: LOCATION_AND_DATE_FORMAT.VERTICAL,
  listLocationAndDateFormat: LIST_LOCATION_AND_DATE_FORMAT.COMPACT,
  showImage: true,
  cardsPerRow: 3,
  cardLocationAndDateFormat: LIST_LOCATION_AND_DATE_FORMAT.COMPACT,
  showCountdown: false,
  showOfferRibbon: true,
  showRecurringRibbon: true,
  showTitle: true,
  showDescription: false,
  showDate: true,
  showLocation: true,
  showShareOptions: true,
  showFullDividers: true,
  showHorizontalDividers: true,
  showCompactDividers: true,
  showLabels: true,
  showRSVPTitle: false,
  showMembers: true,
  listShowMainTitle: true,
  listShowDate: true,
  listShowDayOfWeek: true,
  listShowVenueName: true,
  listShowFullDate: true,
  listShowLocation: true,
  listShowDescription: true,
  listShowSocialShare: false,
  listShowImage: true,
  listShowAdditionalInfo: true,
  listShowMembers: true,
  borderWidth: 0,
  buttonsStyle: BUTTONS_STYLE.FULL,
  buttonsBorderWidth: 1,
  buttonsBorderRadius: 0,
  roundedButtonsBorderRadius: 100,
  listButtonBorderRadius: 0,
  listRoundedButtonBorderRadius: 100,
  listButtonBorderWidth: 1,
  buttonHeight: 44,
  oneButtonWidth: 140,
  verticalDividerWidth: 1,
  verticalDividerHeight: 94,
  horizontalDividerWidth: 20,
  horizontalDividerHeight: 1,
  compactHorizontalDividerWidth: 36,
  compactHorizontalDividerHeight: 1,
  countdownMarginBottom: 30,
  titleMarginBottom: 36,
  descriptionMarginBottom: 42,
  dateMarginBottom: 42,
  locationMarginBottom: 54,
  dateAndLocationMarginBottom: 54,
  rsvpMarginBottom: 30,
  imageWidth: 50,
  imageOpacity: 70,
  listButtonStyle: BUTTONS_STYLE.FULL,
  cardMinWidth: 294,
  cardMargins: 20,
  cardBorderWidth: 1,
  sideBySideLayoutMargins: 30,
  listImagePosition: 5,
  listImageOpacity: 100,
  listImageWidth: 50,
  listDividerWidth: 1,
  listStripWidth: 20,
  listBorderWidth: 0,
  calendarCellBorderWidth: 1,
  calendarBorderWidth: 0,
  calendarWeekStartDay: DEFAULT_WEEK_START,
  calendarListTextSize: 14,
  calendarListDividerWidth: 1,
  calendarDetailsTitleTextSize: 16,
  calendarDetailsDateLocationTextSize: 14,
  calendarDetailsDescriptionTextSize: 14,
  cardDescriptionFontSize: 15,
  todayButtonBorderRadius: 0,
  todayRoundedButtonBorderRadius: 100,
  todayButtonStyle: BUTTONS_STYLE.HOLLOW,
})

export const defaultTexts = (
  settings: WidgetComponentSettings,
  t: TFunction,
  responsive = false,
): Partial<WidgetComponentTextSettings> => {
  const listButtonText = responsive ? t('listTicketsButtonText') : t('listRSVPButtonText')

  return {
    time: t('whenLabel'),
    location: t('whereLabel'),
    RSVPTitle: t('RSVPTitle'),
    registrationClosedButtonText: t('registrationClosedButtonText'),
    oneButton: t('oneButtonText'),
    listButtonText,
    todayButtonText: t('todayButtonText'),
    listGeneralTitle: t('listGeneralTitle'),
    ...{
      multiRsvpButtonText: settings?.texts?.listButtonText ?? t('listRSVPButtonText'),
      multiTicketedButtonText: settings?.texts?.listButtonText ?? t('listTicketsButtonText'),
      multiNoRegButtonText: settings?.texts?.registrationClosedButtonText ?? t('viewer_listNoRegButtonText'),
    },
  }
}

export const fillSettingsDefaults = (
  settings: WidgetComponentSettings,
  {rtl, responsive, t}: WidgetFillSettingsDefaultsProps,
): WidgetComponentSettings => ({
  ...defaultStyleParams(rtl),
  ...(settings ?? ({} as WidgetComponentSettings)),
  texts: {
    ...defaultTexts(settings, t as TFunction, responsive),
    ...(settings?.texts ?? {}),
  } as WidgetComponentTextSettings,
})
