import {CategoryStateState} from '@wix/ambassador-wix-events-web/types'
import {createAsyncAction} from '../services/redux-toolkit'

export const initialize = createAsyncAction('INITIALIZE_MY_EVENTS', async (_, {getState, dispatch}) => {
  const state = getState()
  const componentCategory = state.myEvents.componentCategory

  if (componentCategory) {
    return {category: componentCategory}
  }

  if (state.componentConfig?.byEventId?.eventId?.length) {
    const category = await dispatch(createComponentCategory()).unwrap()
    return {category, reload: true}
  }

  return {category: null}
})

export const createComponentCategory = createAsyncAction(
  'CREATE_COMPONENT_CATEGORY',
  async (_, {getState, extra: {serverApi, Wix}}) => {
    const {category} = await serverApi.createCategory({
      name: Wix.Utils.getOrigCompId(),
      eventIds: getState().componentConfig?.byEventId?.eventId ?? [],
      states: [CategoryStateState.COMPONENT],
    })

    return category
  },
)

export const getComponentCategory = createAsyncAction(
  'GET_COMPONENT_CATEGORIES',
  async (_, {extra: {serverApi, Wix}}) => {
    const componentCategories = await serverApi.getComponentCategories()
    const compId = Wix.Utils.getOrigCompId()
    return componentCategories.categories.find(category => category.name === compId)
  },
)
