import {isActive, isEnded, EVENT_FILTER_TYPE} from '@wix/wix-events-commons-statics'
import {StatusFilter} from '@wix/events-types'
import {WidgetComponentSettings} from '../../stylesParams'
import {WidgetSettingsState} from '../reducers'

// @ts-expect-error
export const getComponentConfig = (state: WidgetSettingsState): WidgetComponentConfig => state.componentConfig

export const getComponentSettings = (state: WidgetSettingsState): WidgetComponentSettings =>
  // @ts-expect-error
  getComponentConfig(state).settings

export const getEventFilterType = (state: WidgetSettingsState): EVENT_FILTER_TYPE => {
  const compConfig = state.componentConfig
  const {FUTURE_AND_PAST, PAST_ONLY} = StatusFilter
  const {MANUAL, UPCOMING_AND_PAST, PAST, UPCOMING} = EVENT_FILTER_TYPE

  if (compConfig.byEventId) {
    return MANUAL
  } else if (
    compConfig.byStatus?.filter === FUTURE_AND_PAST ||
    compConfig.byCategoryId?.statusFilter === FUTURE_AND_PAST
  ) {
    return UPCOMING_AND_PAST
  } else if (compConfig.byStatus?.filter === PAST_ONLY || compConfig.byCategoryId?.statusFilter === PAST_ONLY) {
    return PAST
  } else {
    return UPCOMING
  }
}

export const getCategoryFilter = (state: WidgetSettingsState) => state.componentConfig.byCategoryId?.categoryId?.[0]

export const isEventVisibleOld = (state: WidgetSettingsState, event: wix.events.Event) => {
  const compConfig = state.componentConfig
  const {FUTURE_AND_PAST, PAST_ONLY} = StatusFilter

  if (compConfig.byEventId) {
    return compConfig.byEventId.eventId.includes(event.id)
  } else if (
    compConfig.byStatus?.filter === FUTURE_AND_PAST ||
    compConfig.byCategoryId?.statusFilter === FUTURE_AND_PAST
  ) {
    return true
  } else if (compConfig.byStatus?.filter === PAST_ONLY || compConfig.byCategoryId?.statusFilter === PAST_ONLY) {
    return isEnded(event)
  } else {
    return isActive(event)
  }
}

export const isEventVisible = (event: wix.events.Event, filterType: EVENT_FILTER_TYPE) => {
  if (filterType === EVENT_FILTER_TYPE.UPCOMING_AND_PAST || filterType === EVENT_FILTER_TYPE.MANUAL) {
    return true
  }

  if (filterType === EVENT_FILTER_TYPE.UPCOMING) {
    return isActive(event)
  }

  if (filterType === EVENT_FILTER_TYPE.PAST) {
    return isEnded(event)
  }
}

export const isManualFilterType = (state: WidgetSettingsState) => getEventFilterType(state) === EVENT_FILTER_TYPE.MANUAL

export const manualVisibleEventsCount = (state: WidgetSettingsState) =>
  state.componentConfig.byEventId ? state.componentConfig.byEventId.eventId.length : 0
