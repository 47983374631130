import {IUser, ControllerParams} from '@wix/yoshi-flow-editor'
import {EVENT_FILTER_TYPE, EVENT_SORT_ORDER, RECURRING_FILTER} from '@wix/wix-events-commons-statics'
import {addQueryParams, stringifyParams} from '@wix/panda-js-utils'
import {CalendarDates, EventsDates} from '@wix/events-types'
import {UPDATE_SITE_SETTINGS} from '../../../../commons/actions/site-settings'
import {SiteSettingsSettings} from '../../../../commons/types/state'
import {Api as BaseApi} from '../../../../commons/utils/api'
import {UPDATE_COMPONENT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {LOAD_MEMBERS_FOR_EVENTS, PROMPT_LOGIN} from '../actions/members'
import {getWidgetData} from '../../shared-api'

export class Api extends BaseApi {
  constructor(controllerParams: ControllerParams) {
    super(controllerParams)

    this.registrar = {
      [UPDATE_COMPONENT.NAME]: this.updateComponent,
      [UPDATE_COMPONENT_DRAFT.NAME]: this.updateComponentDraft,
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [LOAD_MEMBERS_FOR_EVENTS.NAME]: this.getGuestLists,
    }
  }

  getWidgetData = getWidgetData(this.api)

  loadEvents = (args: LoadEventsParams): Promise<LoadEventsResponse> =>
    this.api.get(
      addQueryParams(
        '/web/paginated-events/viewer',
        stringifyParams({
          ...args,
          draft: this.viewMode !== 'site',
          compId: this.compId,
        }),
      ),
    )

  loadCalendarEvents = (args: LoadCalendarEventsParams): Promise<LoadCalendarEventsResponse> =>
    this.api.get(
      addQueryParams(
        '/web/calendar-events/viewer',
        stringifyParams({
          ...args,
          draft: this.viewMode !== 'site',
          compId: this.compId,
          tz: this.tz,
        }),
      ),
    )

  updateSiteSettings = (settings: SiteSettingsSettings) => {
    return this.api.put(`/web/site-settings`, {settings})
  }

  updateComponentDraft = (component: wix.events.editor.WebComponentConfig, members = false, paidPlans = false) =>
    this.api.put(`/web/component/${this.compId}/draft?members=${members}&paidPlans=${paidPlans}&noEvents=true`, {
      component,
    })

  updateComponent = (component: wix.events.editor.WebComponentConfig) =>
    this.api.put(`/web/component/${this.compId}`, {component})

  createEvent = (
    data: {
      event: wix.events.EventData
      language: string
      timezone: string
      start: string
      end: string
    },
    ADI: boolean,
  ): Promise<{event: wix.events.Event; ADI: boolean}> => {
    return this.api.post('/adi/event', data).then(({event}) => ({event, ADI}))
  }

  getGuestLists = (eventIds: string[]): Promise<GuestLists> =>
    this.api.get(`/web/members?eventIds=${JSON.stringify(eventIds)}`)

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  onLogin = (handler: LoginHandler) => {
    this.controller.wixCodeApi.user.onLogin(handler)
  }
}

export type LoginHandler = (user: IUser) => void

interface LoadEventsParams {
  offset: number
  filter: wix.events.editor.StatusFilter
  byEventId: boolean
  members: boolean
  paidPlans: boolean
  locale: string
  categoryId: string[]
  recurringFilter?: RECURRING_FILTER
  filterType: EVENT_FILTER_TYPE
  sortOrder: EVENT_SORT_ORDER
}

export interface LoadEventsResponse {
  events: ExtendedEvent[]
  dates: {events: EventsDates}
  hasMore: boolean
}

interface LoadCalendarEventsParams {
  referenceDate: string
  filter: wix.events.editor.StatusFilter
  byEventId: boolean
  members: boolean
  paidPlans: boolean
  locale: string
  categoryId: string[]
  showcase: boolean
  filterType: EVENT_FILTER_TYPE
  sortOrder: EVENT_SORT_ORDER
}

export interface LoadCalendarEventsResponse {
  events: ExtendedEvent[]
  dates: {events: EventsDates; calendar: CalendarDates}
}
